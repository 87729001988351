import { useGetDashboard } from "@/api/dashboard/queries";
// import { useGetSyncTransactions } from "@/api/plaid/queries";
import { Separator } from "@/components/ui/separator";
import { useUserStore } from "@/store/user";
import { createFileRoute } from "@tanstack/react-router";
import ConnectToBank from "./-components/connect-to-bank";
import ConsumptionAndEmissions from "./-components/consumption-and-emissions";
import HomeOwnership from "./-components/cost-homeownership";
import EnergyUsage from "./-components/energy-usage";
import EstimateFromEPC from "./-components/estimate-from-epc";
import Header from "./-components/header";
import HomeHealthScore from "./-components/home-health-score";
import Overview from "./-components/overview";

export const Route = createFileRoute("/dashboard/")({
  component: Dashboard,
});

function Dashboard() {
  const { userId, api_key, isConnectedBank } = useUserStore((state) => state);
  const { data } = useGetDashboard({ userId, api_key });

  if (!data) return;

  return (
    <div className="flex justify-center items-center flex-col p-6">
      <Header address={data.address} />
      <HomeHealthScore score={isConnectedBank ? data.score + 50 : data.score} />
      <Separator className="mb-4" />
      <Overview />
      {isConnectedBank ? <HomeOwnership /> : <ConnectToBank />}
      <EstimateFromEPC
        currentEnergyRating={data.epcData.CURRENT_ENERGY_RATING}
        potentialEnergyRating={data.epcData.POTENTIAL_ENERGY_RATING}
        inspectionDate={data.epcData.INSPECTION_DATE}
      />
      <ConsumptionAndEmissions
        energyConsumptionCurrent={data.epcData.ENERGY_CONSUMPTION_CURRENT}
        co2={data.epcData.CO2_EMISSIONS_CURRENT}
      />
      <Separator className="mb-4" />
      <EnergyUsage />
    </div>
  );
}
